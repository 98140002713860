.download__block {
  margin: 0;
  padding: 35px 15px;
}
.download__block__ttl {
  font-size: 23px;
  font-weight: 700;
  text-align: center;
}
.download__block__lead {
  margin-top: 30px;
}
.download__block .tbl_1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.download__block .inner {
  margin: 0 auto;
  padding: 0;
  width: 100%;
}
.download__block .th {
  border: 1px solid #FFF;
}
.download__block .version {
  height: 30px;
}
.download__block .td {
  border: 1px solid #C0DBEC;
  text-align: center;
}
.download__block .txt {
  margin-top: 40px;
}
.download__block ol {
  padding-left: 25px;
  margin-top: 20px;
}
.download__block li {
  list-style-type: decimal;
}
.download__intro {
  padding: 35px 15px 30px;
}
.download__intro .inner {
  max-width: 1000px;
}
.download__pages {
  padding: 0 15px 55px;
}
.download__pages .inner {
  max-width: 1000px;
}
.download__contents__box {
  display: none;
}
.download__contents__box.open {
  display: block;
}
.download__contents__box .ttl {
  font-size: x-large;
}
.download__contents__box h4 {
  margin: 40px 0 15px;
  font-size: 15px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .download__block {
    padding: 75px 15px 60px;
  }
  .download__block__ttl {
    font-size: 35px;
    margin-bottom: 50px;
  }
  .download__block__lead {
    margin-top: 56px;
    text-align: center;
  }
  .download__intro {
    padding: 75px 15px 52px;
  }
  .download__intro p {
    text-align: center;
  }
  .download__pages {
    margin-top: -40px;
    padding: 0 15px 60px;
  }
}
@media screen and (min-width: 768px) and (min-width: 992px) {
  .download__block {
    padding: 60px 15px;
  }
  .download__block .inner {
    margin: 0 auto;
    padding: 0;
  }
  .download__block ul {
    margin-top: 18px;
  }
}
@media screen and (min-width: 768px) and (min-width: 1100px) {
  .download__box {
    padding: 54px 0;
  }
  .download__box h4 {
    font-size: 18px;
  }
}