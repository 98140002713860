.download {

	&__block {
		margin: 0;
		padding: 35px 15px;

		&__ttl{
			font-size: 23px;
			font-weight: 700;
			text-align: center;
		}

		&__lead{
			margin-top: 30px;
		}

        .tbl_1{
            width:80%;
            margin-left: auto;
            margin-right: auto;
        }

		.inner {
			margin: 0 auto;
			padding: 0;
			width: 100%;
		}

        .th{
            border: 1px solid #FFF;
        }

        .version{
            height: 30px;
        }

        .td{
            border: 1px solid #C0DBEC;
            text-align: center;
        }

        .txt{
            margin-top: 40px;
        }

        ol{
            padding-left: 25px;
            margin-top: 20px;
        }

        li{
            list-style-type:decimal;
        }
	}

	&__intro {
		padding: 35px 15px 30px;

		.inner {
			max-width: 1000px;
		}
	}

	&__pages {
		padding: 0 15px 55px;

		.inner {
			max-width: 1000px;
		}
	}

	//------------------------------------------
		// download__contents
    &__contents {

        &__box {
            display: none;

            &.open {
                display: block;
            }

            .ttl{
                font-size:x-large;
            }

            h4 {
                margin: 40px 0 15px;
                font-size: 15px;
                font-weight: 700;
            }
        }
    }
}

@media screen and (min-width:768px) {
	.download {

		&__block {
			padding: 75px 15px 60px;

			&__ttl{
				font-size: 35px;
                margin-bottom:50px;
			}

			&__lead{
				margin-top: 56px;
				text-align: center;
			}
		}

		&__intro {
			padding: 75px 15px 52px;

			p {
				text-align: center;
			}
		}

		&__pages {
			margin-top: -40px;
			padding: 0 15px 60px;
		}
	}

	@media screen and (min-width: 992px) {
		.download {

			//------------------------------------------
			&__block {
				padding: 60px 15px;

				.inner {
					margin: 0 auto;
					padding: 0;
				}

				ul {
					margin-top: 18px;
				}
			}
		}
	}

	@media screen and (min-width: 1100px) {
		.download {

			//------------------------------------------
			// download__contents
            &__box {
                padding: 54px 0;

                h4 {
                    font-size: 18px;
                }
            }
		}
	}
}